<template>
    <iframe id="show-iframe" frameborder=0 name="showHere" scrolling=no src=""></iframe>      
</template>

<script>
//import wx from 'weixin-js-sdk'

export default {
  name: '课件',
  components: {
  },data () {
    return {
        userId:0,
        projectId:0,
        courseId:0,
        classId:0,
        frameSrc:'',
        wxInfo:null,
        lastTime:0,
      }
  },
  methods:{
      getUrlKey(name) {
        const mainUrl = window.location.href
        return decodeURIComponent(
          (
            new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)')
            .exec(mainUrl) || [, ""])[1].replace(/\+/g, '%20')
          ) || null
        
      },

      updateTime(nTime){

        this.$http.get('https://api.jinluedu.net/api/square/updateTimeThird',
          {params: { id: this.courseId,
                     project:this.projectId,
                     member:this.userId,
                     time:nTime,
                     lasttime:this.lastTime
                    }
          }).then((res) => {

        });


      }
      /*
      loadWx(){
        let mainUrl = window.location.href.split('#')[0]

        this.$http.get('https://api.jinluedu.net/api/auth/getSign',{params: { url: mainUrl }}).then((response) => {
          this.wxInfo = response.data.data.wx
          wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: this.$appId, // 必填，公众号的唯一标识
              timestamp: this.wxInfo.timeStamp, // 必填，生成签名的时间戳
              nonceStr: this.wxInfo.nonceStr, // 必填，生成签名的随机串
              signature: this.wxInfo.sigNature, // 必填，签名
              jsApiList: [
                  "scanQRCode"
              ], // 必填，需要使用的JS接口列表
          });

        });
      }
      */
      
  },
  mounted(){    
      document.title = '课件'
      let that = this
      window.addEventListener("message", function(event) {
      console.log(event)
      if (event.origin != 'https://api.share.zsjykj.net')
        return

      that.updateTime(parseInt(event.data))

//      alert('get ' + event?JSON.stringify(event):'none data')      
    },false);

      this.userId = this.getUrlKey('userid')
      this.classId = parseInt(this.getUrlKey('classid'))
      this.projectId = parseInt(this.getUrlKey('projectid'))
      this.courseId = parseInt(this.getUrlKey('courseid'))

      this.lastTime = parseInt(this.getUrlKey('lasttime')) || 0
      if(this.userId !='' && this.userId != undefined && this.projectId >0 && this.courseId > 0 && this.classId >0){
 //       this.frameSrc = 'https://hy.ypp365.com/testme.html'
        let userFormat = this.userId + 'p' + this.projectId + 'c' + this.courseId
        this.frameSrc = 'https://api.share.zsjykj.net/CourseVideoWebChart.html?appkey=enNfMEKx&userid='
        + userFormat + '&classid=' + this.classId + '&autoplay=MQ&speed=MA&playcontinuously=0&lasttime='+ this.lastTime
        
      }

    const oIframe = document.getElementById('show-iframe');
    const deviceWidth = document.documentElement.clientWidth;
    const deviceHeight = document.documentElement.clientHeight;
    oIframe.style.width = deviceWidth + 'px';
    oIframe.style.height = deviceHeight + 'px';
    oIframe.src = this.frameSrc

    window.addEventListener("beforeunload", () => {
      console.log('unload')
      if (document == undefined || document == null)
      {
        
      }else{
        console.log(document.getElementById('show-iframe').src)
        document.getElementById('show-iframe').src = ''
        console.log('free src')
      }
    });


    //this.loadWx()
  },
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

textarea {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

a {
  color: #54a4fc;
  text-decoration: none;
}
a:hover {
  color: #2380e6;
}
a:active {
  color: #2380e6;
}
</style>
